import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

// import core components 
import { Layout, Container } from 'layouts';
import { remarkMD } from 'utils/remark';

const MDTemplate = ({pageContext}) => {
    const [ innerHtml, setInnerHtml ] = useState();

    useEffect(() => {
        async function loadHTML() {
            setInnerHtml(await remarkMD(pageContext.mdString));
        }
        loadHTML();
    }, [])
  return (
    <Layout title={pageContext.title}>
        <Container>
            <div
                id="___gatsby"
                dangerouslySetInnerHTML={{ __html: innerHtml }}
            />
        </Container>
    </Layout>
  );
};

export default MDTemplate;